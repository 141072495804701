import useFormHandler from "../../hooks/useFormHandler";
import './index.scss'

export const FormBlock = () => {
    const {handleSubmit, handleChange, formData, loading,  } = useFormHandler()
    const {name, email, phone} = formData

    return (<form className="form-blocks__form" onSubmit={handleSubmit}>
        <input
            type="text"
            className="form-blocks__input"
            placeholder="Ім’я"
            name="name"
            value={name}
            onChange={handleChange}
            required
        />
        <input
            type="text"
            className="form-blocks__input"
            placeholder="E-mail"
            name="email"
            value={email}
            onChange={handleChange}
            required
        />
        <input
            type="text"
            className="form-blocks__input"
            placeholder="Номер телефону"
            name="phone"
            value={phone}
            onChange={handleChange}
            required
        />
        <button
            className="button-main form-blocks__button custom-height"
            type="submit"
            disabled={loading}
        >
            {loading ? 'Відправляємо' : 'Хочу менше паперів!'}
        </button>
    </form>
)
}