import './index.scss'
import ArrowRightPrimary from '../../img/arrow-right-primary.svg'
import { casesLinks } from "../../constants/navigation"

const CasesMenuItem = ({ item }) => {
	return (
		<a className="cases-menu-overlay__item" href={item.href} target="_blank">
			<div className="cases-menu-overlay__item-header">
				<h5 className="text-14px dark fw-500">{item.title}</h5>
				<img className="cases-menu-overlay__item-arrow" src={ArrowRightPrimary} alt="Arrow right"/>
			</div>
			<p className="text-12px">{item.text}</p>
		</a>
	)
}

const CasesMenuOverlay = ({ showCasesMenu, setShowCasesMenu }) => {
	return (
		<div className={`cases-menu-overlay__overlay ${showCasesMenu ? 'active' : ''}`} onClick={() => setShowCasesMenu(false)}>
			<div className={`cases-menu-overlay ${showCasesMenu ? 'active' : ''}`}>
				<div className="container">
					<div className="cases-menu-overlay__content">
						{casesLinks.map((item, idx) => (<CasesMenuItem item={item} key={idx}/>))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default CasesMenuOverlay