import { useState } from "react";
import { useSnackbar } from "notistack";

const scriptUrl = 'https://script.google.com/macros/s/AKfycbySsH0qoDYngvKhDy6VnOOkSMrJFkTSqHhN38w7YT6crZZvvkoAttTWDqVk6oVO7g5W/exec'


const useFormHandler = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.phone) {
            return;
        }

        const data = new FormData();
        Object.entries(formData).forEach(([key, value]) => data.append(key, value));
        data.append("url", window.location.href);
        data.append("created_at", new Date());

        try {
            setLoading(true);
            await fetch(scriptUrl, {
                method: "POST",
                body: data,
            });

            setFormData({ name: "", email: "", phone: "" });
            enqueueSnackbar("Ваші дані успішно збережено!", { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Помилка при відправці.", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    return { formData, loading, handleChange, handleSubmit };
};

export default useFormHandler;
