import {useEffect, useState} from "react"

import Header from '../components/header'
import Footer from '../components/footer'
import IntroBlock from '../components/intro-block'
import CasesTextBlock from '../components/cases-text-block'
import HowItWorks from '../components/how-it-works'
import CasesJoinUs from '../components/cases-join-us'
import CasesRatingBlock from '../components/cases-rating-block'
import CasesBenefitsBlock from "../components/cases-benefits-block"
import CasesTemplatesBlock from "../components/cases-templates-block"
import CasesTemplatesAdvantages from "../components/cases-templates-advantages"
import { Helmet } from "react-helmet-async"

const Fop = () => {
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)
	const [mdDevice, setMdDevice] = useState(
			window.matchMedia('(max-width: 960px)').matches
	)
	const href = new URL('https://my.dubidoc.com.ua/auth')
	const partnersLink = href.toString()
	const introImage = {
		src: require('../img/fop/fop-intro.png'),
		alt: 'Fop intro image'
	}
	const introTitle = `Динамічні шаблони документів для взаємодії з <span>ФОПами</span>`
	let howItWorksImageRightPosition = '-30px'
	if (mdDevice) {
		howItWorksImageRightPosition = '35px'
	}
	if (smDevice) {
		howItWorksImageRightPosition = '-30px'
	}
	const howItWorksItems = [
		{
			title: 'Створіть шаблон документа',
			text: 'Використовуйте наш зручний та інтуїтивно зрозумілий редактор для створення шаблону договору, акту або NDA. Легко додавайте текст, підписи, дати, поля для заповнення та інші елементи, необхідні для вашого бізнесу. Створення документів ще ніколи не було таким простим!',
			image: {
				src: require('../img/fop/act-file.png'),
				alt: 'Act document',
				styles: {
					alignItems: 'flex-end'
				}
			},
			absoluteImages: [
				{
					src: require('../components/templates/img/templates-fields.png'),
					alt: 'Templates dynamic fields list',
					styles: {
						maxWidth: smDevice ? '145px' : '100%',
						left: mdDevice ? '-10px' : '-95px',
						top: mdDevice ? '50px' : '60px'
					}
				}
			],
			contentFirst: !mdDevice
		},
		{
			title: 'Поділіться документом',
			text: 'Наша система генерує унікальний QR-код або посилання на ваш шаблон. Надішліть його ФОПу через месенджер, електронну пошту або робочий чат. Ви також можете розмістити QR-код на корпоративному порталі для швидкого доступу. Поділіться своїми документами за лічені секунди!',
			image: {
				src: require('../img/fop/chatting.png'),
				alt: 'Chat',
				fullWidth: true
			},
			contentFirst: false
		},
		{
			title: 'Отримайте заповнений і підписаний документ',
			text: 'ФОП переходить за посиланням або сканує QR-код, заповнює всі необхідні поля та підписує документ онлайн. Ви миттєво отримуєте готовий документ, який автоматично з’являється у вашому кабінеті. Усі документи впорядковані та готові до використання або зберігання. З Dubidoc ви завжди на крок попереду!',
			image: {
				src: require('../img/fop/act-file-filled.png'),
				alt: 'Clinics document filled',
				styles: {
					alignItems: 'flex-end'
				}
			},
			absoluteImages: [
				{
					src: require('../img/clinics/dynamic-fields-guide-tooltip.png'),
					alt: 'Templates dynamic fields fill tooltip',
					styles: {
						maxWidth: smDevice ? '150px' : '100%',
						right: howItWorksImageRightPosition,
						bottom: smDevice ? '12px' : '-45px'
					}
				}
			],
			contentFirst: !mdDevice
		},
	]
	const benefitsItems = [
		{
			title: 'Без паперової тяганини',
			text: 'Усі документи створюються та підписуються онлайн',
			image: {
				src: require('../components/cases-benefits-block/img/file.png'),
				alt: 'Document icon'
			},
			style: {
				maxWidth: 'calc(50% - 16px)',
				flexGrow: 1
			}
		},
		{
			title: 'Чітка структура зберігання',
			text: 'Усі документи впорядковані та доступні у вашому особистому кабінеті. Знайти потрібний договір можна за лічені секунди',
			image: {
				src: require('../components/cases-benefits-block/img/file-box.png'),
				alt: 'Box of documents'
			},
			style: {
				maxWidth: 'calc(50% - 16px)',
				flexGrow: 1
			}
		},
		{
			title: 'Доступ до API для інтеграції',
			text: 'Використовуйте API, щоб автоматично заповнювати шаблони даними з вашої облікової системи',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			},
			style: {
				maxWidth: 'calc(33.33333% - 16px)',
				height: '272px'
			}
		},
		{
			title: 'Швидкість і зручність',
			text: 'ФОПи заповнюють документи за кілька хвилин',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			},
			style: {
				maxWidth: 'calc(33.33333% - 16px)',
				height: '272px'
			}
		},
		{
			title: 'Універсальність',
			text: 'Один шаблон може використовуватися для тисяч документів',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			},
			style: {
				maxWidth: 'calc(33.33333% - 16px)',
				height: '272px'
			}
		}
	]

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
		window
				.matchMedia('(max-width: 960px)')
				.addEventListener('change', e => setMdDevice(e.matches))
	}, [])

	return (
			<div className="App">
				<Helmet>
					<title>Динамічні шаблони документів для роботи з ФОПами | Dubidoc</title>
					<meta name="description"
					      content="Автоматизуйте створення та підписання договорів, актів і NDA з ФОПами. Почніть безкоштовно! Використовуйте динамічні шаблони, QR-коди та API для швидкого і простого документообігу."/>
					<link rel="canonical" href="https://dubidoc.com.ua/fop/"/>
				</Helmet>
				<Header/>
				<main>
					<IntroBlock
							contentMaxWidth={520}
							image={introImage}
							title={introTitle}
							subtitle="Автоматизуйте оформлення договорів, актів та NDA, спрощуючи процес роботи"
							smDevice={smDevice}
							buttonHref={partnersLink}
					/>
					<CasesTextBlock
						title="Створюйте, надсилайте та отримуйте заповнені договори з вашими підрядниками та контрагентами без зайвих зусиль"
						smDevice={smDevice}
						mdDevice={mdDevice}
					/>
					<CasesTemplatesAdvantages
						title="Використання динамічних шаблонів дозволяє"
						smDevice={smDevice}
						buttonHref={partnersLink}
					/>
					<HowItWorks
						title="Як це працює ?"
						items={howItWorksItems}
						smDevice={smDevice}
						mdDevice={mdDevice}
					/>
					<CasesJoinUs
						smDevice={smDevice}
						mdDevice={mdDevice}
						buttonHref={partnersLink}
					/>
					<CasesRatingBlock
						smDevice={smDevice}
					/>
					<CasesBenefitsBlock
						items={benefitsItems}
						flexConfig={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}
						mdDevice={mdDevice}
						smDevice={smDevice}
					/>
					<CasesTemplatesBlock
						smDevice={smDevice}
						text={'Ми проведемо демо та покажемо, як легко створювати шаблони і швидко отримувати заповнені та підписані документи від ФОПів.'}
					/>
				</main>
				<Footer />
			</div>
	)
}

export default Fop