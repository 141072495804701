import './index.scss'

const ImageContentBlock = ({
   image = {},
   title = null,
   text = null,
   contentFirst = true,
   absoluteImages = [],
   smDevice,
   mdDevice
}) => {

	let imageWidth
	if (smDevice) {
		imageWidth = '85%'
	}
	if (image.fullWidth) {
		imageWidth = '100%'
	}

	const contentStyle = {
		order: contentFirst ? 1 : 2
	}
	const imageWrapperStyle = {
		order: contentFirst ? 2 : 1,
		padding: image.fullWidth ? '0' : '0 24px',
		overflow: image.fullWidth ? 'hidden' : 'visible',
		...image.styles
	}
	const imageStyle = {
		width: imageWidth,
	}
	return (
			<div className="image-content">
				<div className="image-content__content" style={contentStyle}>
					{title && (<h3 className="title-24px image-content__title">{title}</h3>)}
					{text && (<p className="image-content__text">{text}</p>)}
				</div>
				<div className="image-content__image" style={imageWrapperStyle}>
					{absoluteImages.map((image, i) => (<img
							key={i}
							src={image.src}
							alt={image.alt}
							className="image-content__absolute-image"
							style={image.styles}
					/>))}
					<img src={image.src} alt={image.alt} style={imageStyle}/>
				</div>
			</div>
	)
}

export default ImageContentBlock