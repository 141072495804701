import './index.scss'
import ArrowDown from '../../img/arrow-down.svg'

const CasesHeaderButton = ({ showCasesMenu, setShowCasesMenu }) => {
	return (
			<button className="cases-header-button" onClick={() => setShowCasesMenu(!showCasesMenu)}>
				<span className="text-14px fw-400 dark">Кейси</span>
				<img className={`pl-4 ${showCasesMenu ? 'active' : ''}`} src={ArrowDown} alt="Arrow down" />
			</button>
	)
}

export default CasesHeaderButton