import '../about/index.scss'

const AboutPartners = ({ buttonHref }) => {
	return (
		<div className="about">
			<div className="container">
				<div className="about__content about__content--partners">
					<div className="about__left">
						<h1 className="about__title about__title--partners">
							<span>Приєднуйтесь</span> до партнерської програми <span>Dubidoc</span>
						</h1>
						<p className="about__text">Заробляйте, допомагаючи іншим спростити процес підписання документів</p>
						<a id="partners" href={buttonHref} target="_blank" className="button-main">
							Стати партнером
						</a>
					</div>
					<div className="about__right">
						<div className="about__image-wrapper">
							<img
									src={require('./img/screen.png')}
									srcSet={`${require('./img/screen@2x.png')} 2x, ${require('./img/screen@3x.png')} 3x`}
									alt="Інтерфейс мобільного застосунку Дубідок"
									className="about__screen about__screen--partners"
							/>
							<img
									src={require('./img/1.png')}
									srcSet={`${require('./img/1@2x.png')} 2x, ${require('./img/1@3x.png')} 3x`}
									alt="Screen"
									className="about__msg-1 about__msg-1--partners"
							/>
							<img
									src={require('./img/2.png')}
									srcSet={`${require('./img/2@2x.png')} 2x, ${require('./img/2@3x.png')} 3x`}
									alt="Screen"
									className="about__msg-2 about__msg-2--partners"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutPartners