import './index.scss'

const CasesJoinUs = ({ buttonHref, smDevice, mdDevice }) => {
	let titleClass = 'title-32px'
	if (mdDevice) {
		titleClass = 'title-24px'
	}
	if (smDevice) {
		titleClass = 'title-16px'
	}
	return (
		<div className="join-us">
			<div className="container">
				<div className="join-us__content">
					<div className="join-us__images">
						<img src={require('../about-partners/img/1.png')} alt="" className="join-us__image join-us__image--1"/>
						<img src={require('../about-partners/img/1.png')} alt="" className="join-us__image join-us__image--2"/>
						<img src={require('../about-partners/img/2.png')} alt="" className="join-us__image join-us__image--3"/>
						<img src={require('../about-partners/img/2.png')} alt="" className="join-us__image join-us__image--4"/>
					</div>
					<div className="join-us__content-wrapper">
						<h2 className={titleClass}>Долучайтеся до сучасних технологій електронного документообігу</h2>
						<a id="partners" className="button-main" href={buttonHref} target="_blank">Розпочати зараз</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CasesJoinUs