import Header from '../components/header'
import Footer from '../components/footer'
import AboutPartners from '../components/about-partners'
import Banner from '../components/banner'
import AdvantagesPartners from "../components/advantages-partners"
import Cooperation from "../components/cooperation"
import Auditory from "../components/auditory"
import HowItWorks from "../components/how-it-works"
import FaqPartners from '../components/faq-partners'
import ReadyPartners from "../components/ready-partners"
import {useEffect, useState} from "react"
import { Helmet } from "react-helmet-async"

const Partners = () => {
	const href = new URL('https://partner.checkbox.ua/auth/registration')
	const partnersLink = href.toString()
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)
	const [mdDevice, setMdDevice] = useState(
			window.matchMedia('(max-width: 960px)').matches
	)

	const howItWorksItems = [
		{
			title: '1. Увійдіть в свій кабінет або зареєструйтесь',
			text: 'Партнерський кабінет у нас спільний для checkbox та dubidoc, аби все було в одному місці. Зручно, чи не так?',
			image: {
				src: mdDevice ?
					require('../components/how-it-works/img/login-screen-mobile.png')
					: require('../components/how-it-works/img/login-screen.png'),
				alt: 'dubidoc login',
				styles: {
					alignItems: 'flex-end',
					minHeight:  smDevice ? '300px' : '400px'

				}
			},
			contentFirst: !mdDevice
		},
		{
			title: '2. Заберіть своє унікальне реферальне посилання та діліться посиланням з потенційними клієнтами',
			image: {
				src: require('../components/how-it-works/img/referal.png'),
				alt: 'referal link'
			},
			contentFirst: false
		},
		{
			title: '3. Отримуйте винагороду за кожного нового користувача*',
			image: {
				src: require('../components/how-it-works/img/platform.png'),
				alt: 'platform'
			},
			contentFirst: !mdDevice
		}
	]

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
		window
				.matchMedia('(max-width: 960px)')
				.addEventListener('change', e => setMdDevice(e.matches))
	}, [])

	const faqSchema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "Хто такий партнер в рамках програми?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Партнер Dubidoc — це друг, який допомагає бізнесам переходити на сучасний та зручний електронний документообіг. Ви рекомендуєте наш сервіс своїм клієнтам, колегам або знайомим і водночас отримуєте приємний бонус за свою рекомендацію."
				}
			},
			{
				"@type": "Question",
				"name": "Які умови нарахування винагороди?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Наші партнери отримують: разова винагорода: 20 грн за кожного активного клієнта, який зареєструється за реферальним посиланням, створить документ, відправить та підпише його. Сервіс безкоштовний."
				}
			},
			{
				"@type": "Question",
				"name": "Які документи потрібні для початку?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Жодних. Ми надамо всю необхідну інформацію та проконсультуємо вас для успішного старту роботи."
				}
			},
			{
				"@type": "Question",
				"name": "Як я отримую свої виплати?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Після входу до кабінету партнера вам необхідно одноразово підписати договір у розділі \"Налаштування\". Під час запиту на виведення коштів автоматично формується акт, який потрібно підписати. Після цього, протягом 5 робочих днів, ви отримаєте виплату комісійної винагороди за ваших рефералів."
				}
			}
		]
	}

	return (
		<div className="App">
			<Helmet>
				<title>Партнерська програма Dubidoc | Електронний документообіг</title>
				<meta name="description"
				      content="Заробляйте разом з Dubidoc! Рекомендуйте сучасний сервіс електронного документообігу своїм клієнтам чи знайомим. Отримуйте бонуси за кожного нового активного користувача."/>
				<script type="application/ld+json">
					{JSON.stringify(faqSchema)}
				</script>
				<link rel="canonical" href="https://dubidoc.com.ua/partners/"/>
			</Helmet>
			<Header/>
			<main>
				<AboutPartners buttonHref={partnersLink}/>
				<Banner buttonHref={partnersLink}/>
				<AdvantagesPartners buttonHref={partnersLink}/>
				<Cooperation/>
				<Auditory buttonHref={partnersLink}/>
				<HowItWorks title="Як працює наша партнерська програма?" items={howItWorksItems}/>
				<FaqPartners/>
				<ReadyPartners/>
			</main>
			<Footer />
		</div>
	)
}

export default Partners