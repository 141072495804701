import './index.scss'

const CasesTextBlock = ({ title, smDevice, mdDevice }) => {
	let titleClass = 'title-32px'
	if (mdDevice) {
		titleClass = 'title-24px'
	}
	if (smDevice) {
		titleClass = 'title-16px'
	}
	return (
			<div className="cases-text-block">
				<div className="container">
					<div className="cases-text-block__content">
						<div className="cases-text-block__icon">
							<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
								 width="56"
								 height="56"
								viewBox="0 0 120 120"
								 preserveAspectRatio="xMidYMid meet">

								<g transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
								   fill="#464646" stroke="none">
									<path d="M10 670 c0 -37 4 -50 14 -50 8 0 45 -27 83 -60 82 -72 135 -100 192
-100 54 0 99 23 189 99 101 85 129 83 229 -11 91 -85 175 -107 258 -69 22 10
68 44 103 74 34 31 74 60 87 63 22 6 25 11 25 55 0 49 0 49 -27 43 -45 -11
-78 -31 -145 -87 -80 -68 -102 -80 -138 -72 -16 3 -59 33 -97 67 -37 33 -79
66 -93 73 -83 42 -165 22 -265 -66 -114 -99 -128 -99 -237 -8 -77 64 -106 82
-150 93 -28 6 -28 6 -28 -44z"/>
								</g>
							</svg>

						</div>
						<h3 className={titleClass}>{title}</h3>
					</div>
				</div>
			</div>
	)
}

export default CasesTextBlock