import './index.scss'

const CasesRatingBlock = ({ smDevice }) => {
	return (
			<div className="rating">
				<div className="container">
					<h2 className={`${smDevice ? 'title-24px' : 'title-32px'}`}>Оцінки наших користувачів</h2>
					<div className="rating__content">
						<div className="rating__item">
							<div className="rating__item-image">
								<img src={require('./img/rating-1.png')} alt="9.2 of 10 dubidoc client satisfaction rating"/>
							</div>
							<div className="rating__item-content">
								<div className="text-18px dark">Оцінка задоволеності використання сервісу</div>
							</div>
						</div>
						<div className="rating__item">
							<div className="rating__item-image">
								<img src={require('./img/rating-2.png')} alt="9.5 of 10 dubidoc simplicity rating"/>
							</div>
							<div className="rating__item-content">
								<div className="text-18px dark">Оцінка простоти використання сервісу</div>
							</div>
						</div>
						<div className="rating__item">
							<div className="rating__item-image">
								<img src={require('./img/rating-3.png')} alt="9.5 of 10 dubidoc speed rating"/>
							</div>
							<div className="rating__item-content">
								<div className="text-18px dark">Оцінка швидкості використання сервісу</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	)
}

export default CasesRatingBlock