import {useEffect, useState} from "react"

import Header from '../components/header'
import Footer from '../components/footer'
import CasesTextBlock from '../components/cases-text-block'
import HowItWorks from '../components/how-it-works'
import CasesRatingBlock from '../components/cases-rating-block'
import CasesBenefitsBlock from "../components/cases-benefits-block"
import CasesTemplatesBlock from "../components/cases-templates-block"
import { Helmet } from "react-helmet-async"
import IntroBlock from "../components/intro-block";

const Reception = () => {
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)
	const [mdDevice, setMdDevice] = useState(
			window.matchMedia('(max-width: 960px)').matches
	)
	const href = new URL('https://my.dubidoc.com.ua/auth')
	const partnersLink = href.toString()
	let howItWorksImageRightPosition = '-25px'
	if (mdDevice) {
		howItWorksImageRightPosition = '35px'
	}
	if (smDevice) {
		howItWorksImageRightPosition = '-30px'
	}
	const howItWorksItems = [
		{
			title: 'Створіть шаблон вашого договору',
			text: 'У зручному та інтуїтивно зрозумілому редакторі  легко створити шаблон: додайте текст, підписи, галочки, дати та інші поля, які потрібні вашому бізнесу',
			image: {
				src: require('../img/clinics/clinics-document.png'),
				alt: 'Clinics document',
				styles: {
					alignItems: 'flex-end'
				}
			},
			absoluteImages: [
				{
					src: require('../components/templates/img/templates-fields.png'),
					alt: 'Templates dynamic fields list',
					styles: {
						maxWidth: smDevice ? '145px' : '100%',
						left: mdDevice ? '-10px' : '-95px',
						top: mdDevice ? '50px' : '60px'
					}
				}
			],
			contentFirst: !mdDevice
		},
		{
			title: 'Розмістіть QR-код на рецепції',
			text: 'Система автоматично згенерує унікальний QR-код для вашого шаблону. Просто роздрукуйте його та розмістіть у зручному місці — наприклад, на рецепції',
			image: {
				src: require('../img/clinics/woman-scanning-qr.png'),
				alt: 'Woman scanning QR-code',
				fullWidth: true
			},
			contentFirst: false
		},
		{
			title: 'Клієнти сканують код і заповнюють документ',
			text: 'Ваші клієнти сканують QR-код за допомогою смартфона, заповнюють документ за кілька хвилин та одразу надсилають його. Усе цифрове, без паперу та очікувань',
			image: {
				src: require('../img/clinics/clinics-document-filled.png'),
				alt: 'Clinics document filled',
				styles: {
					alignItems: 'flex-end'
				}
			},
			absoluteImages: [
				{
					src: require('../img/clinics/dynamic-fields-guide-tooltip.png'),
					alt: 'Templates dynamic fields fill tooltip',
					styles: {
						maxWidth: smDevice ? '150px' : '100%',
						right: howItWorksImageRightPosition,
						bottom: smDevice ? '12px' : '60px'
					}
				}
			],
			contentFirst: !mdDevice
		},
	]
	const benefitsItems = [
		{
			title: 'Без паперової тяганини',
			text: 'Усі документи створюються, заповнюються та підписуються за допомогою Дія й мають таку ж юридичну силу, як паперові. Ніяких друкованих форм, сканів чи очікувань.',
			image: {
				src: require('../components/cases-benefits-block/img/file.png'),
				alt: 'Document icon'
			}
		},
		{
			title: 'Чітка структура зберігання',
			text: 'Усі документи впорядковані та доступні у вашому особистому кабінеті. Знайти потрібний договір можна за лічені секунди',
			image: {
				src: require('../components/cases-benefits-block/img/file-box.png'),
				alt: 'Box of documents'
			}
		},
		{
			title: 'Один шаблон — тисячі документів',
			text: 'Створіть універсальний шаблон документа для вашого бізнесу та використовуйте його для будь-якої кількості клієнтів',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			}
		},
		{
			title: 'Доступ до API для інтеграції',
			text: 'Використовуйте API, щоб автоматично заповнювати шаблони даними з вашої облікової системи',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			}
		}
	]

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
		window
				.matchMedia('(max-width: 960px)')
				.addEventListener('change', e => setMdDevice(e.matches))
	}, [])

	return (
			<div className="App">
				<Helmet>
					<title>Динамічні шаблони документів для клінік | Dubidoc</title>
					<meta name="description"
					      content="Створюйте, надсилайте, отримуйте заповнені та підписані договори для клінік онлайн. Почніть безкоштовно! Використовуйте QR-коди, API та інтуїтивний редактор Dubidoc для швидкого документообігу."/>
					<link rel="canonical" href="https://dubidoc.com.ua/reception/"/>
				</Helmet>
				<Header/>
				<main>
					<IntroBlock
						contentMaxWidth={640}
						subtitle="Створюйте, надсилайте, отримуйте заповнені та підписані договори з клієнтами"
						smDevice={smDevice}
						buttonHref={partnersLink}
					/>
					<CasesTextBlock
						title="Створіть свій перший динамічний шаблон документа безкоштовно та отримайте його заповненим від клієнта"
						smDevice={smDevice}
						mdDevice={mdDevice}
					/>
					<HowItWorks
						title="Як це працює ?"
						items={howItWorksItems}
						smDevice={smDevice}
						mdDevice={mdDevice}
					/>
					<CasesRatingBlock
						smDevice={smDevice}
					/>
					<CasesBenefitsBlock
						items={benefitsItems}
						gridConfig={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}
						mdDevice={mdDevice}
						smDevice={smDevice}
					/>
					<CasesTemplatesBlock
						smDevice={smDevice}
						text={'Ми проведемо демо та покажемо,  як легко створювати шаблони документів і швидко отримувати підписані договори від клієнтів клініки.'}
					/>
				</main>
				<Footer />
			</div>
	)
}

export default Reception