import './index.scss'
import {HashLink as Link} from "react-router-hash-link"
import SupportButton from "../support-button"
import {useEffect, useState} from "react"
import { casesLinks } from "../../constants/navigation"
import ArrowRightPrimary from '../../img/arrow-right-primary.svg'

const BurgerMenuOverlay = ({ buttonHref }) => {
	const [showCasesMenu, setShowCasesMenu] = useState(false)

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, []);

	return (
			<div className="burger-menu-overlay">
				<nav className="nav">
					<Link to="/partners" className="nav__link lg">Партнерам</Link>
					<button className={`nav__link lg full-width d-flex justify-space-between ${showCasesMenu ? 'active' : ''}`} onClick={() => setShowCasesMenu(!showCasesMenu)}>
						<span>Кейси</span>
						<svg className={`nav__link-arrow ${showCasesMenu ? 'active' : ''}`} xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
							<path d="M12.8333 1.5L8.0311 6.85869C7.46397 7.49155 6.53594 7.49155 5.96881 6.85869L1.16663 1.5" stroke="#121212" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</button>
					{showCasesMenu && (
							<div className={`nav__inner ${showCasesMenu ? 'active': ''}`}>
								{
									casesLinks.map((link, index) => (
											<a className="cases-menu-overlay__item" href={link.href} target="_blank" key={index}>
												<div className="cases-menu-overlay__item-header">
													<h5 className="text-14px dark fw-500">{link.title}</h5>
													<img className="cases-menu-overlay__item-arrow" src={ArrowRightPrimary} alt="Arrow right"/>
												</div>
												<p className="text-12px">{link.text}</p>
											</a>
									))
								}
							</div>
					)}
				</nav>
				<div className="burger-menu-overlay__footer">
					<SupportButton fullWidth={true}/>
					<a href={buttonHref} target="_blank" className="button-main" id="vhid">
						Вхід
					</a>
				</div>
			</div>
	)
}

export default BurgerMenuOverlay