import './index.scss'

const AdvantagesPartnersItem = ({ item }) => {
	return (
			<div className="advantages-partners__item">
				<div className="advantages-partners__item-icon">
					<img
							src={require(`./img/${item.icon}.png`)}
							alt="Icon"
					/>
				</div>
				<p>{item.text}</p>
			</div>
	)
}

const AdvantagesPartners = ({ buttonHref }) => {
	const items = [
		{
			icon: '1',
			text: 'Ми надаємо безкоштовний сервіс, де немає ніяких витрат на підписання документів'
		},
		{
			icon: '2',
			text: 'Легка винагорода: 20 грн за кожного активного клієнта'
		},
		{
			icon: '3',
			text: 'Сервіс Dubidoc надає зручний веб-інтерфейс для управління вашими рефералами'
		},
		{
			icon: '4',
			text: 'Залучайте широкий спектр цільової аудиторії: від бухгалтерів, та ріелторів до IT-компаній та сервісних бізнесів. Та взагалі всіх'
		}
	]
	return (
		<div className="advantages-partners">
			<div className="container">
				<h2 className="advantages-partners__title">Чому варто стати нашим партнером?</h2>
				<div className="advantages-partners__content">
					{items.map((item, key) => {
						return (
							<AdvantagesPartnersItem
									item={item}
									key={key}
							/>
						)
					})}
				</div>
				<div className="advantages-partners__footer">
					<a id="partners" href={buttonHref} target="_blank" className="button-secondary">Стати партнером</a>
				</div>
			</div>
		</div>
	)
}

export default AdvantagesPartners