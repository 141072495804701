import {useEffect, useState} from "react"

import Header from '../components/header'
import Footer from '../components/footer'
import IntroBlock from '../components/intro-block'
import CasesTextBlock from '../components/cases-text-block'
import HowItWorks from '../components/how-it-works'
import CasesJoinUs from '../components/cases-join-us'
import CasesRatingBlock from '../components/cases-rating-block'
import CasesBenefitsBlock from "../components/cases-benefits-block"
import CasesTemplatesBlock from "../components/cases-templates-block"
import { Helmet } from "react-helmet-async"

const DocumentManagement = () => {
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)
	const [mdDevice, setMdDevice] = useState(
			window.matchMedia('(max-width: 960px)').matches
	)
	const href = new URL('https://my.dubidoc.com.ua/auth')
	const partnersLink = href.toString()
	const introImage = {
		src: require('../img/document-management/coworking.png'),
		alt: 'Document management intro image'
	}
	const introTitle = `Динамічні шаблони документів для <span>кадрового документообігу</span>`
	let howItWorksImageRightPosition = '-25px'
	if (mdDevice) {
		howItWorksImageRightPosition = '35px'
	}
	if (smDevice) {
		howItWorksImageRightPosition = '-30px'
	}
	const howItWorksItems = [
		{
			title: 'Створіть шаблон документа',
			text: 'У зручному та інтуїтивно зрозумілому редакторі ви легко створите шаблон кадрового документа: заяви, накази, згоди тощо. Додайте текст, підписи, галочки, дати та інші поля, які потрібні вашому бізнесу',
			image: {
				src: require('../img/document-management/document.png'),
				alt: 'Dynamic document',
				styles: {
					alignItems: 'flex-end'
				}
			},
			absoluteImages: [
				{
					src: require('../components/templates/img/templates-fields.png'),
					alt: 'Templates dynamic fields list',
					styles: {
						maxWidth: smDevice ? '145px' : '100%',
						left: mdDevice ? '-10px' : '-95px',
						top: mdDevice ? '50px' : '60px'
					}
				}
			],
			contentFirst: !mdDevice
		},
		{
			title: 'Поділіться документом',
			text: 'Система створює унікальний QR-код або посилання для вашого шаблону. Надішліть його у робочий чат, персонально співробітнику, розмістіть на корпоративному порталі або надрукуйте для використання в офісі',
			image: {
				src: require('../img/document-management/chatting.png'),
				alt: 'Chat',
				fullWidth: true
			},
			contentFirst: false
		},
		{
			title: 'Співробітники отримують доступ і заповнюють документ',
			text: 'Ваші співробітники переходять за посиланням або сканують QR-код, заповнюють документ за кілька хвилин і відправляють його. Усе цифрове, без сканування, конвертацій чи паперової тяганини',
			image: {
				src: require('../img/document-management/document-filled.png'),
				alt: 'Document filled',
				styles: {
					alignItems: 'flex-end'
				}
			},
			absoluteImages: [
				{
					src: require('../img/clinics/dynamic-fields-guide-tooltip.png'),
					alt: 'Templates dynamic fields fill tooltip',
					styles: {
						maxWidth: smDevice ? '150px' : '100%',
						right: howItWorksImageRightPosition,
						bottom: smDevice ? '12px' : '60px'
					}
				}
			],
			contentFirst: !mdDevice
		},
	]
	const benefitsItems = [
		{
			title: 'Без паперової тяганини',
			text: 'Усі документи створюються, заповнюються та підписуються онлайн. Ніяких друкованих форм, сканів чи очікувань',
			image: {
				src: require('../components/cases-benefits-block/img/file.png'),
				alt: 'Document icon'
			},
			style: {
				maxWidth: 'calc(50% - 16px)',
				flexGrow: 1
			}
		},
		{
			title: 'Один шаблон — тисячі документів',
			text: 'Створіть універсальні шаблони кадровових документів для вашого бізнесу та використовуйте їх для будь-якої кількості співробітниками',
			image: {
				src: require('../components/cases-benefits-block/img/file-box.png'),
				alt: 'Box of documents'
			},
			style: {
				maxWidth: 'calc(50% - 16px)',
				flexGrow: 1
			}
		},
		{
			title: 'Доступ до API для інтеграції',
			text: 'Використовуйте API, щоб автоматично заповнювати шаблони даними з вашої облікової системи',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			},
			style: {
				maxWidth: 'calc(33.33333% - 16px)',
				height: '272px'
			}
		},
		{
			title: 'Легкий доступ до документів',
			text: 'Усі документи впорядковані та доступні у вашому особистому кабінеті. Знайти потрібний договір можна за лічені секунди',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			},
			style: {
				maxWidth: 'calc(33.33333% - 16px)',
				height: '272px'
			}
		},
		{
			title: 'Універсальність',
			text: 'Один шаблон може використовуватися для тисяч документів',
			image: {
				src: require('../components/cases-benefits-block/img/files.png'),
				alt: 'Documents'
			},
			style: {
				maxWidth: 'calc(33.33333% - 16px)',
				height: '272px'
			}
		}
	]

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
		window
				.matchMedia('(max-width: 960px)')
				.addEventListener('change', e => setMdDevice(e.matches))
	}, [])

	return (
			<div className="App">
				<Helmet>
					<title>Динамічні шаблони документів для кадрового документообігу | Dubidoc</title>
					<meta name="description"
					      content="Автоматизуйте створення, заповнення та підписання кадрових документів: заяв, наказів, згод. Почніть безкоштовно! Використовуйте QR-коди, посилання та API для сучасного електронного документообігу."/>
					<link rel="canonical" href="https://dubidoc.com.ua/document-management/"/>
				</Helmet>
				<Header/>
				<main>
					<IntroBlock
							contentMaxWidth={625}
							image={introImage}
							title={introTitle}
							subtitle="Автоматизуйте створення, заповнення та підписання кадрових документів, спрощуючи роботу з персоналом"
							smDevice={smDevice}
							buttonHref={partnersLink}
					/>
					<CasesTextBlock
							title="Легко керуйте кадровими документами, їх заповненям та підписанням"
							smDevice={smDevice}
							mdDevice={mdDevice}
					/>
					<HowItWorks
							title="Як це працює ?"
							items={howItWorksItems}
							smDevice={smDevice}
							mdDevice={mdDevice}
					/>
					<CasesJoinUs
							smDevice={smDevice}
							mdDevice={mdDevice}
							buttonHref={partnersLink}
					/>
					<CasesRatingBlock
							smDevice={smDevice}
					/>
					<CasesBenefitsBlock
							items={benefitsItems}
							flexConfig={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}
							mdDevice={mdDevice}
							smDevice={smDevice}
					/>
					<CasesTemplatesBlock
							smDevice={smDevice}
							text={'Ми проведемо демо та покажемо, як легко створювати шаблони і швидко отримувати заповнені та підписані документи від співробітників.'}
					/>
				</main>
				<Footer />
			</div>
	)
}

export default DocumentManagement