import React from 'react'
import { createRoot } from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles/fonts.css'
import './styles/index.scss'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom'
import Main from './pages/Main.js'
import PublicOffer from './pages/PublicOffer'
import PrivacyPolicy from "./pages/PrivacyPolicy"
import Partners from "./pages/Partners"
import Reception from "./pages/Reception"
import Fop from "./pages/Fop"
import DocumentManagement from "./pages/DocumentManagement"
import NotFound from "./pages/NotFound"
import { HelmetProvider } from "react-helmet-async"
import { SnackbarProvider } from "notistack"

const router = createBrowserRouter([
  { path: '/', element: <Main /> },
  { path: '/public-offer', element: <PublicOffer /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/partners', element: <Partners /> },
  { path: '/reception', element: <Reception /> },
  { path: '/clinic', element: <Navigate to='/reception' replace /> }, // редирект с /clinic на /reception
  { path: '/fop', element: <Fop /> },
  { path: '/document-management', element: <DocumentManagement /> },
  { path: '*', element: <NotFound /> },
])

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </SnackbarProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
