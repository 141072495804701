import './index.scss'
import {useEffect, useRef, useState} from "react";
import Slider from 'react-slick'
import {useIsCurrentPath} from "../../hooks/useIsCurrentPath";
import {FormBlock} from "../form-block";

const IntroBlock = ({ buttonHref, contentMaxWidth = 600, title = null, subtitle, image = null, smDevice }) => {
	const [keyWord, setKeyWord] = useState('')

	const isReception = useIsCurrentPath('reception')

	const contentStyle = {
		maxWidth: typeof contentMaxWidth === 'number' ? contentMaxWidth + 'px' : contentMaxWidth,
	}
	const keyWords = ['клінік', 'салонів', 'спортзалів']
	const typeWriter = (text, i, fnCallback) => {
		if (i < (text.length)) {
			setKeyWord(text.substring(0, i+1))

			setTimeout(function() {
				typeWriter(text, i + 1, fnCallback)
			}, 200);
		} else if (typeof fnCallback === 'function') {
			setTimeout(fnCallback, 0);
		}
	}
	const startTextAnimation = (i) => {
		if (typeof keyWords[i] === 'undefined') {
			setTimeout(function() {
				startTextAnimation(0);
			}, 2000);
		}
		if (i < keyWords[i]?.length) {
			typeWriter(keyWords[i], 0, function(){
				startTextAnimation(i + 1);
			});
		}
	}
	let sliderRef = useRef(null)
	const settings = {
		className: 'slider variable-width',
		autoplay: true,
		autoplaySpeed: 2000,
		infinite: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		variableWidth: false
	}
	const sliderImages = [
		{
			src: require('../../img/clinics/clinics-intro-image.png'),
			alt: 'Clinics intro image'
		},
		{
			src: require('../../img/clinics/saloon.png'),
			alt: 'Saloon intro image'
		},
		{
			src: require('../../img/clinics/gym.png'),
			alt: 'Gym intro image'
		}
	]

	useEffect(() => {
		if (!title) {
			startTextAnimation(0)
		}
	}, []);

	return (
		<div className="intro">
			<div className="container">
				<div className="intro__content-wrapper">
					<div className="intro__content" style={contentStyle}>
						{title ? (
							<h1 className={`intro__title mb-24 ${smDevice ? 'title-24px' : 'title-40px'}`} dangerouslySetInnerHTML={{__html: title}}></h1>
						) : (
							<h1 className={`intro__title mb-24 ${smDevice ? 'title-24px' : 'title-40px'}`}>Динамічні
								шаблони
								<br/>
								документів для <span className="animated">{keyWord}</span></h1>
						)}
						{subtitle && (
							<p className={`intro__text ${smDevice ? 'text-14px mb-32' : `text-18px ${isReception ? 'mb-32' : 'mb-48'}`}`}>{subtitle}</p>
						)}
						{isReception
							? <FormBlock />
							: <a id="partners" className="button-main" href={buttonHref} target="_blank">Розпочати зараз</a>
						}
					</div>
					<div className="intro__image-wrapper">
						{image ? (
							<div className="intro__image">
								<img src={image.src} alt={image.alt}/>
							</div>
						) : (
							<Slider
								ref={slider => {
									sliderRef = slider;
								}}
								{...settings}
							>
								{sliderImages.map((item, idx) => (
									<div className="intro__image" key={idx}>
										<img src={item.src} alt={item.alt}/>
									</div>
								))}
							</Slider>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default IntroBlock