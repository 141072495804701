import './index.scss'
import useFormHandler from "../../hooks/useFormHandler";

const CasesTemplatesBlock = ({ smDevice, text }) => {
	const {handleSubmit, handleChange, formData, loading,  } = useFormHandler()
	const {name, email, phone} = formData

	return (
		<div className="cases-templates">
			<div className="container">
				<div className="cases-templates__content-wrapper">
					<div className="cases-templates__content">
						<h2 className={`${smDevice ? 'title-24px' : 'title-32px'} cases-templates__title`}>Заповніть форму</h2>
						<p className={`${smDevice ? 'text-16px' : 'text-20px'} cases-templates__text`}>{text}</p>
						<svg className="cases-templates__img" width="316" height="141" viewBox="0 0 316 141" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M128.358 106.873L129.358 106.86L128.358 106.873ZM84.3032 136.239L84.8352 135.392L84.3032 136.239ZM97.845 79.7666L98.5128 80.5109L97.845 79.7666ZM315.212 6.44395C315.627 6.80856 315.668 7.44041 315.303 7.85523L309.361 14.6151C308.997 15.0299 308.365 15.0706 307.95 14.706C307.535 14.3414 307.495 13.7095 307.859 13.2947L313.141 7.28595L307.132 2.0044C306.717 1.63979 306.676 1.00793 307.041 0.593114C307.406 0.178289 308.038 0.1376 308.452 0.502205L315.212 6.44395ZM2.05697 39.3867C2.9026 52.5102 10.9351 60.9882 22.8101 66.9144C34.7257 72.8609 50.3491 76.1475 66.0223 78.8943C81.6219 81.6282 97.3035 83.8302 109.073 87.6392C114.965 89.5459 119.981 91.8854 123.553 94.9704C127.158 98.0841 129.296 101.967 129.358 106.86L127.359 106.885C127.305 102.652 125.488 99.2846 122.245 96.4839C118.97 93.6545 114.248 91.4159 108.457 89.542C96.8629 85.7898 81.406 83.6209 65.6771 80.8643C50.0216 78.1206 34.1263 74.797 21.9171 68.704C9.66718 62.5907 0.968662 53.5998 0.0611057 39.5153L2.05697 39.3867ZM129.358 106.86C129.514 119.102 122.599 129.458 113.471 135.352C104.357 141.235 92.8389 142.783 83.7712 137.086L84.8352 135.392C93.0609 140.56 103.721 139.266 112.386 133.671C121.038 128.086 127.504 118.327 127.359 106.885L129.358 106.86ZM83.7712 137.086C75.647 131.982 72.1552 123.478 74.1807 113.229C76.1915 103.055 83.618 91.1898 97.1771 79.0224L98.5128 80.5109C85.1238 92.5257 78.037 104.033 76.1428 113.617C74.2632 123.127 77.4746 130.768 84.8352 135.392L83.7712 137.086ZM97.1771 79.0224C124.316 54.6688 201.04 13.5044 314.488 6.19711L314.616 8.19298C201.534 15.4767 125.262 56.5077 98.5128 80.5109L97.1771 79.0224Z" fill="#8F257D"/>
						</svg>
					</div>

					<form className="cases-templates__form" onSubmit={handleSubmit}>
						<p className="cases-templates__label">
							Контактна інформація
						</p>
						<input
							type="text"
							className="cases-templates__input"
							placeholder="Ім’я"
							name="name"
							value={name}
							onChange={handleChange}
							required
						/>
						<input
							type="text"
							className="cases-templates__input"
							placeholder="E-mail"
							name="email"
							value={email}
							onChange={handleChange}
							required
						/>
						<input
							type="text"
							className="cases-templates__input"
							placeholder="Номер телефону"
							name="phone"
							value={phone}
							onChange={handleChange}
							required
						/>
						<button
							className="button-main cases-templates__button custom-height"
							type="submit"
							disabled={loading}
						>
							{loading ? "Відправлення..." : "Відправити"}
						</button>
					</form>

				</div>
			</div>
		</div>
	)
}

export default CasesTemplatesBlock