import {useQuery} from "./useQuery";
import {useMemo} from "react";

const AUTH_URL = 'https://my.dubidoc.com.ua/auth'

export const useAuthUrlWithRef = () => {
    const ref = useQuery('ref')
    return useMemo(() => {
        const href = new URL(AUTH_URL);
        if (ref) {
            href.searchParams.set("ref", ref);
        }
        return href.toString();
    }, [ref]);
}