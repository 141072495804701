import './index.scss'
import tg from './img/tg.svg'
import sms from './img/sms.svg'

const SupportButton = ({ fullWidth = false }) => {
  return (
    <div className="support-button">
      <div className={`support-button__btn ${fullWidth ? 'full-width' : ''}`}>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.8083 15.2751C18.8083 15.5751 18.7416 15.8834 18.6 16.1834C18.4583 16.4834 18.275 16.7667 18.0333 17.0334C17.625 17.4834 17.175 17.8084 16.6666 18.0167C16.1666 18.2251 15.625 18.3334 15.0416 18.3334C14.1916 18.3334 13.2833 18.1334 12.325 17.7251C11.3666 17.3167 10.4083 16.7667 9.45829 16.0751C8.49996 15.3751 7.59163 14.6001 6.72496 13.7417C5.86663 12.8751 5.09163 11.9667 4.39996 11.0167C3.71663 10.0667 3.16663 9.11675 2.76663 8.17508C2.36663 7.22508 2.16663 6.31675 2.16663 5.45008C2.16663 4.88341 2.26663 4.34175 2.46663 3.84175C2.66663 3.33341 2.98329 2.86675 3.42496 2.45008C3.95829 1.92508 4.54163 1.66675 5.15829 1.66675C5.39163 1.66675 5.62496 1.71675 5.83329 1.81675C6.04996 1.91675 6.24163 2.06675 6.39163 2.28341L8.32496 5.00842C8.47496 5.21675 8.58329 5.40841 8.65829 5.59175C8.73329 5.76675 8.77496 5.94175 8.77496 6.10008C8.77496 6.30008 8.71663 6.50008 8.59996 6.69175C8.49163 6.88341 8.33329 7.08341 8.13329 7.28341L7.49996 7.94175C7.40829 8.03341 7.36663 8.14175 7.36663 8.27508C7.36663 8.34175 7.37496 8.40008 7.39163 8.46675C7.41663 8.53341 7.44163 8.58341 7.45829 8.63341C7.60829 8.90841 7.86663 9.26675 8.23329 9.70008C8.60829 10.1334 9.00829 10.5751 9.44163 11.0167C9.89163 11.4584 10.325 11.8667 10.7666 12.2417C11.2 12.6084 11.5583 12.8584 11.8416 13.0084C11.8833 13.0251 11.9333 13.0501 11.9916 13.0751C12.0583 13.1001 12.125 13.1084 12.2 13.1084C12.3416 13.1084 12.45 13.0584 12.5416 12.9667L13.175 12.3417C13.3833 12.1334 13.5833 11.9751 13.775 11.8751C13.9666 11.7584 14.1583 11.7001 14.3666 11.7001C14.525 11.7001 14.6916 11.7334 14.875 11.8084C15.0583 11.8834 15.25 11.9917 15.4583 12.1334L18.2166 14.0917C18.4333 14.2417 18.5833 14.4167 18.675 14.6251C18.7583 14.8334 18.8083 15.0417 18.8083 15.2751Z" stroke="#121212" strokeWidth="1.5" strokeMiterlimit="10"/>
          <path d="M15.9167 7.50016C15.9167 7.00016 15.525 6.2335 14.9417 5.6085C14.4083 5.0335 13.7 4.5835 13 4.5835" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M18.8333 7.50008C18.8333 4.27508 16.225 1.66675 13 1.66675" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

        <span>Підтримка</span>
      </div>
      <div className="support-button__menu">
        <div className="support-button__menu-content">
          <a
            className="support-button__menu-item"
            href="mailto:dubidoc@checkbox.ua"
            target="_blank"
          >
            <div className="support-button__social-btn">
              <img src={sms} alt="Email" />
            </div>
            <span>Написати на пошту</span>
          </a>
          <a
            className="support-button__menu-item"
            href="https://t.me/Dubi_Doc_UA_bot"
            target="_blank"
          >
            <div className="support-button__social-btn">
              <img src={tg} alt="Telegram" />
            </div>
            <span>Написати в telegram</span>
          </a>
          <div className="support-button__divider" />
          <p className="support-button__label">
            Підтримка (з 9:00 до 21:00)
          </p>
        </div>
      </div>
    </div>
  )
}

export default SupportButton