export const casesLinks = [
	{
		title: 'Рецепція',
		text: 'Створюйте, надсилайте, отримуйте заповнені та підписані договори з клієнтами',
		href: '/reception/'
	},
	{
		title: 'Кадрові документи',
		text: 'Автоматизуйте створення, заповнення та підписання кадрових документів, спрощуючи роботу з персоналом',
		href: '/document-management/'
	},
	{
		title: 'ФОПи',
		text: 'Автоматизуйте оформлення договорів, актів та NDA, спрощуючи процес роботи',
		href: '/fop/'
	}
]