import { useNavigate } from "react-router-dom";
import './not-found-block.scss'

const NotFoundBlock = () => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate('/')
    }

    return (
        <main className='not-found__container'>
            <div className="not-found__card">
                <div className="not-found__image"
                     style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/racoon-404.svg)` }} />
                <div className="not-found__text">
                    Вибачте, але сторінку не знайдено. Будь ласка, поверніться на головну сторінку.
                </div>

                <div>
                    <button onClick={handleGoBack} className="not-found__button">Повернутись на головну</button>
                </div>

            </div>
        </main>
    )
}

export default NotFoundBlock