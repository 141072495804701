import './index.scss'
import {useEffect, useState} from "react";
import sendIcon from './img/send.svg'
import { useSnackbar } from "notistack";

const ReadyPartners = () => {
	const { enqueueSnackbar } = useSnackbar()
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [smDevice, setSmDevice] = useState(
			window.matchMedia('(max-width: 560px)').matches
	)

	useEffect(() => {
		window
				.matchMedia('(max-width: 560px)')
				.addEventListener('change', e => setSmDevice(e.matches))
	}, [])

	const bannerBg = smDevice ? require('./img/banner-mobile.png') : require('./img/banner.png')
	const scriptUrl = 'https://script.google.com/macros/s/AKfycbyJJuluflHQTD1_wi8U-TYA7MRXZa5gCboSzuBen3rWmSJdymrd512UW24bw4ePz0C9/exec'
	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!email) {
			return
		}
		const data = new FormData()
		data.append('email', email)
		data.append('created_at', new Date())

		try {
			setLoading(true)
			await fetch(scriptUrl, {
				method: 'POST',
				body: data
			})
			setEmail('')
			enqueueSnackbar("Ваш email успішно збережено!", { variant: "success" })
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
		}
	}
	return (
			<div className="ready-partners">
				<div className="container">
					<div className="ready-partners__banner">
						<img
								src={bannerBg}
								srcSet={`${require(`./img/banner@2x.png`)} 2x, ${require(`./img/banner@3x.png`)} 3x`}
								alt="banner"
						/>
						<div className="ready-partners__content">
							<h2 className="ready-partners__title">Готові почати та приєднатися до партнерки Dubidoc?</h2>
							<p className="ready-partners__text">Пам’ятайте, що час — це гроші! Допоможіть своїм клієнтам заощадити час і отримуйте винагороду за це з Dubidoc</p>
							<form onSubmit={handleSubmit} className="ready-partners__form">
								<div className="ready-partners__input-wrapper">
									<input
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											type="text"
											name="email"
											className="ready-partners__input"
											placeholder="Email"
									/>
									<button className="ready-partners__input-button" type="submit">
										{ loading ? (
											<svg className="button-main__loader" xmlns="http://www.w3.org/2000/svg"
											     viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381">
												<circle fill="transparent" cx="43.80952380952381" cy="43.80952380952381" r="20"
												        strokeWidth="3.8095238095238093" strokeDasharray="100" stroke="#fff"/>
											</svg>
										) : (
											<img src={sendIcon} alt="send icon"/>
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
	)
}

export default ReadyPartners